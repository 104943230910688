import axios from 'axios'
import _ from 'lodash'
import {
    LOAD_APP_SETTINGS,
    LOAD_THEME_3_SETTINGS,
    ADD_CUSTOM_MENU_LINKS,
    ADD_INFLUENCER_HELP_POPUP,
    INFLUENCER_HELP_POPUP_HIDE,
    ADD_INFLUENCER_HELP_LIST,
    ADD_INFLUENCER_HELP_DETAIL,
    INFLUENCER_HELP_RESUME_API_CALL,
    UPDATE_INFLUENCER_HELP_LIST,
    UPDATE_INFLUENCER_HELP_LIST_NOTIFICATION,
    ADD_INFLUENCER_HELP_LIST_NOTIFICATION,
    SET_HELP_NOTIFICATION_UNREAD_COUNT,
    RESET_HELP_NOTIFICATION,
    SET_NOTIFICATION_FILTER,
    SET_HELP_TAG_FILTER,
    SET_HELP_TAGS,
    SET_IS_HELP_ENABLE,
    SET_IS_PUBLIC_POST_ENABLE,
    SET_IS_BLOG_COMMENTS_ENABLE,
    UPDATE_LINK_PAGE_DATA,
    UPDATE_FEED_TAG_LIST,
    SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS,
    IS_SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS_LOADING,
    IS_SUBSCRIPTION_EMAIL_NOTIFICATION_BUTTON_LOADING,
    IS_PAGINATION_ON_EXCLUSIVE_CONTENT_SETTING_LOADING,
    IS_PAGINATION_ON_EXCLUSIVE_CONTENT_BUTTON_LOADING,
    IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED,
    UPDATE_TIPS_PAGE_DATA,
    UPDATE_THEME_2_BANNER_SETTINGS,
    UPDATE_THEME_2_BANNER_SETTINGS_AFTER_FILE_UPLOAD,
    UPDATE_APP_SETTING_SINGLE_KEY,
    IS_REDIS_CACHE_SETTING_LOADING,
    IS_REDIS_CACHE_ENABLED,
    IS_REDIS_CACHE_BUTTON_LOADING
} from './types'
import { getAppBaseUrl } from './api'
import { setSweetAlert } from './sweetAlertActions'
import { restoreFirstPageBlogs } from './blogActions'
import { setIsExclusiveContentPaginationEnabled } from './authActions'

const BASE_URL = getAppBaseUrl()

// Get App Settings for normal users
export const getAppSettings = (callback) => dispatch => {
    axios.get(BASE_URL + '/api/app_settings/get')
        .then(res => {
            dispatch(appSettings(res.data))
            if (_.isEmpty(res.data.appSettings.feed_tags) === false) {
                dispatch({ type: UPDATE_FEED_TAG_LIST, payload: res.data.appSettings.feed_tags })
            }
            callback()
            dispatch(updateUserLastSeen())
        })
}

export const updateUserLastSeen = () => () => {
    axios.post(BASE_URL + '/api/users/update_last_seen')
        .then(() => {

        })
}

// Get Theme 3 Settings for admin
export const getAllTheme3Settings = (callback) => dispatch => {
    axios.get(BASE_URL + '/api/app_settings/get_all_theme_3_settings')
        .then(res => {
            dispatch(theme3Settings(res.data))
            callback()
        })
}

// Upload theme image
export const uploadThemeImage = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/app_settings/upload-theme-image', data)
        .then((res) => {
            if (res.data.success === 1) {
                alert(res.data.message)
            }
            dispatch(getAppSettings(callback))
        })
}

// Delete theme image
export const deleteThemeImage = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/app_settings/delete-theme-image', data)
        .then((res) => {
            if (res.data.success === 1) {
                alert(res.data.message)
            }
            dispatch(getAppSettings(callback))
        }).catch(error => {
            console.log(error.message)
            alert(error.message)
        })
}
// Upload theme background image
export const uploadThemeBackgroundImage = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/app_settings/upload_theme_background_image', data)
        .then((res) => {
            if (res.data.success === 1) {
                alert(res.data.message)
                dispatch(getAppSettings(callback))
            }
        }).catch(error => {
            console.log(error.message)
            alert(error.message)
        })
}

// Upload theme background image
export const updateThemeBackgroundSetting = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/app_settings/update_use_background_image_key', data)
        .then((res) => {
            if (res.data.success === 1) {
                alert(res.data.message)
                dispatch(getAppSettings(callback))
            }
        }).catch(error => {
            console.log(error.message)
            alert(error.message)
        })
}

// Update chat settings
export const updateChatSettings = (data, callback) => () => {
    axios.post(BASE_URL + '/api/app_settings/update_chat_settings', data)
        .then((res) => {
            if (res.data.success === 1) {
                alert(res.data.message)
                callback()
            }
        }).catch(error => {
            console.log(error.message)
            alert(error.message)
        })
}

/**
 * Get Resend Mass Message Settings
 * @param {*} callback Send the response object
 */
export const getResendMassMessageSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/app_settings/get_resend_mass_message_settings')
        .then((res) => {
            callback(res.data.data)
        }).catch(error => {
            console.log(error.message)
            alert(error.message)
            callback()
        })
}

/**
 * Set Get Resend Mass Message settings in database
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const saveResendMassMessageSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/app_settings/save_resend_mass_message_settings', data)
        .then((res) => {
            alert(res.data.message)
            dispatch(getAppSettings(callback))
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            callback()
        })
}

/**
 * Get CCBill Settings
 * @param {*} callback Send the response object
 */
export const getCCBillSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/ccbill-settings/get-ccbill-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

/**
 * save CCBill settings
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const saveCCBillSettings = (data, callback) => () => {
    axios.post(BASE_URL + '/api/ccbill-settings/save-ccbill-settings', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

/**
 * Get AWS Settings
 * @param {*} callback Send the response object
 */
export const getAWSSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/aws-settings/get-aws-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

/**
 * save AWS settings
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const saveAWSSettings = (data, callback) => () => {
    axios.post(BASE_URL + '/api/aws-settings/save-aws-settings', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

/**
 * Get Platform Settings
 * @param {*} callback Send the response object
 */
export const getPlatformSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/platform-settings/get-platform-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

/**
 * save Platform settings
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const savePlatformSettings = (data, callback) => () => {
    axios.post(BASE_URL + '/api/platform-settings/save-platform-settings', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

/**
 * Get Purchase Notification Settings
 * @param {*} callback Send the response object
 */
export const getPurchaseNotificationSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/settings/purchase-notification-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

/**
 * save Purchase Notification settings
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const savePurchaseNotificationSettings = (data, callback) => () => {
    axios.post(BASE_URL + '/api/settings/purchase-notification-settings', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

/**
 * Get Gross Earning Settings
 * @param {*} callback Send the response object
 */
export const getReportingSettings = (callback) => dispatch => {
    axios.get(BASE_URL + '/api/reporting-page')
        .then((res) => {
            callback(res.data.data)
        }).catch(error => {
            console.log(error.message)
            const payload = {
                description: error.message
            }
            dispatch(setSweetAlert(payload))
            callback()
        })
}

/**
 * Set Get Gross Revenue settings in database
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const saveReportingSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/reporting-page', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            dispatch(getAppSettings(callback))
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            callback()
        })
}

/**
 * Get Hide Earnings Settings
 * @param {*} Return Send the response object
 */
export const getHideEarningSetting = () => async (dispatch) => {
    const url = `${BASE_URL}/api/reporting-page/hide-earnings`
    try {
        const response = await axios.get(url)
        return response.data.data
    } catch (error) {
        const payload = {
            description: error.message
        }
        dispatch(setSweetAlert(payload))
    }
}

/**
 * Set Hide Earnings settings in database
 * @param {Object} data Request data to be sent to server
 * @param {*} Return to the UI for success or error
 */
export const saveHideEarningSettings = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/reporting-page/hide-earnings`
    try {
        const response = await axios.post(url, data)
        const payload = {
            description: response.data.message
        }
        dispatch(setSweetAlert(payload))
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
    }
}

/**
 * Get CCBill settings
 * @param {*} callback Return to the UI for success or error
 */
export const getCCBillSetting = (callback) => () => {
    axios.get(BASE_URL + '/api/app_settings/get_ccbill_settings')
        .then((res) => {
            callback(res.data)
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
        })
}

/**
 *  Get Email Configuration Settings
 * @param {*} callback Send the response object
 */
export const getEmailConfigurationSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/app_settings/get_email_configuration_settings')
        .then((res) => {
            callback(res.data.data)
        }).catch(error => {
            console.log(error.message)
            alert(error.message)
            callback()
        })
}

/**
 * Save CCBill settings in database
 * @param {*} callback Return to the UI for success or error
 */
export const saveCCBillSetting = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/app_settings/save_ccbill_settings', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            callback(res.data)
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
        })
}

/**
 * Set Get Email Configuration settings in database
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const saveEmailConfigurationSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/app_settings/save_email_configuration', data)
        .then((res) => {
            alert(res.data.message)
            dispatch(getAppSettings(callback))
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            callback()
        })
}

// Load app settings in store
export const appSettings = (res) => {
    return {
        type: LOAD_APP_SETTINGS,
        payload: res
    }
}

// Load all theme 3 settings in store
export const theme3Settings = (res) => {
    return {
        type: LOAD_THEME_3_SETTINGS,
        payload: res
    }
}

export const getMenuSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/menu-settings/get-menu-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveMenuSettings = (data, callback) => () => {
    axios.post(BASE_URL + '/api/menu-settings/save-menu-settings', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

export const getTipPageSettings = (callback) => dispatch => {
    axios.get(BASE_URL + '/api/tip-page-settings/get-tip-page-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'There was a problem while getting tips page settings.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const saveTipPageSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/tip-page-settings/save-tip-page-settings', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            dispatch(updateTipsPageData(res.data.data.data))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getSignUpPageSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/signup-page-settings/get-signup-page-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveSignUpPageSettings = (data, callback) => () => {
    axios.post(BASE_URL + '/api/signup-page-settings/save-signup-page-settings', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

export const getTheme2Settings = (callback) => () => {
    axios.get(BASE_URL + '/api/settings/theme-2-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveTheme2Settings = (data, callback) => () => {
    axios.post(BASE_URL + '/api/settings/theme-2-settings', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

export const getMembershipPricingSetting = (callback) => () => {
    axios.get(BASE_URL + '/api/get/membership-price')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveMembershipPricingSetting = (data, callback) => () => {
    axios.post(BASE_URL + '/api/update/membership-price', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

export const getWebsiteSetting = (callback) => () => {
    axios.get(BASE_URL + '/api/get/website-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveWebsiteSetting = (data, callback) => () => {
    axios.put(BASE_URL + '/api/update/website-settings', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

export const getWatermarkSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/watermark-settings/watermark-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveWatermarkSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/watermark-settings/watermark-settings', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getThemeSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/theme-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveThemeSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/theme-settings', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getWebsiteSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/website-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveWebsiteSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/website-settings', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getBannerImageTextSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/banner-image-text')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveBannerImageTextSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/banner-image-text', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getMinimumAndMaximumAmounts = (callback) => () => {
    axios.get(BASE_URL + '/api/minimum-and-maximum-amounts')
        .then((res) => {
            return callback(res.data.data)
        }).catch(() => {
            return callback()
        })
}

export const saveMinimumAndMaximumAmounts = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/minimum-and-maximum-amounts', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getAgeModal = (callback) => () => {
    axios.get(BASE_URL + '/api/age-modal')
        .then((res) => {
            return callback(res.data.data)
        }).catch(() => {
            return callback()
        })
}

export const saveAgeModal = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/age-modal', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

/**
 * Get custom menu
 * @param {*} callback Send the response object
 */
export const getCustomMenuLinks = (pageNum, callback) => () => {
    axios.get(BASE_URL + `/api/custom-menu/get?page_num=${pageNum}`)
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

/**
 * save custom menu
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const saveCustomMenuLink = (data, callback) => () => {
    axios.post(BASE_URL + '/api/custom-menu/save', data)
        .then((res) => {
            alert(res.data.message)
            return callback(res.data.data)
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

export const updateCustomMenuStatus = (data, callback) => () => {
    axios.post(BASE_URL + '/api/custom-menu/status/update', data)
        .then((res) => {
            alert(res.data.message)
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

export const getAllCustomMenuLinks = (callback) => dispatch => {
    axios.get(BASE_URL + '/api/custom-menu/all/get')
        .then(res => {
            dispatch(addCustomMenuLinks(res.data.data))
            callback()
        })
}

export const addCustomMenuLinks = (data) => {
    return {
        type: ADD_CUSTOM_MENU_LINKS,
        payload: data
    }
}


export const getContactUsSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/contact-us-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(() => {
            return callback()
        })
}

export const saveContactUsSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/contact-us-settings', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getLoginSignupTitle = (callback) => () => {
    axios.get(BASE_URL + '/api/login-and-signup-title')
        .then((res) => {
            return callback(res.data.data)
        }).catch(() => {
            return callback()
        })
}

export const saveLoginSignupTitle = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/login-and-signup-title', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getMiscellaneousPopUp = (callback) => () => {
    axios.get(BASE_URL + '/api/miscellaneous-pop-up')
        .then((res) => {
            return callback(res.data.data)
        }).catch(() => {
            return callback()
        })
}

export const saveMiscellaneousPopUp = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/miscellaneous-pop-up', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getShop = (callback) => () => {
    axios.get(BASE_URL + '/api/shop')
        .then((res) => {
            return callback(res.data.data)
        }).catch(() => {
            return callback()
        })
}

export const saveShop = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/shop', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))

            return callback()
        })
}

export const getInfluencerHelpPopupData = (callback) => dispatch => {
    axios.get(BASE_URL + '/help/popup-with-notification-count')
        .then(res => {
            dispatch(addInfluencerHelpPopupData(res.data.data))
            return callback()
        }).catch(() => {
            return callback()
        })
}

export const addInfluencerHelpPopupData = (data) => {
    return {
        type: ADD_INFLUENCER_HELP_POPUP,
        payload: data
    }
}

export const setHelpDataAsRead = (id) => dispatch => {
    axios.get(BASE_URL + `/help/read/${id}`)
        .then(res => {
            dispatch(setHelpNotificationUnreadCount(res.data.data))
        })
}

export const setHelpNotificationUnreadCount = (data) => {
    return {
        type: SET_HELP_NOTIFICATION_UNREAD_COUNT,
        payload: data
    }
}

export const influencerPopupHide = () => {
    return {
        type: INFLUENCER_HELP_POPUP_HIDE,
        payload: {}
    }
}

export const getInfluencerHelpData = (filter, callback) => dispatch => {
    // axios.get(BASE_URL + `/help/list/${pageNum}`)
    axios.post(BASE_URL + '/help/list', filter)
        .then(res => {
            const object = {
                currentPage: res.data.data.currentPage,
                totalPages: res.data.data.totalPages
            }
            if (filter.pageNum === 1) {
                dispatch(addInfluencerHelpData(res.data.data))
                dispatch(setHelpTags(res.data.data.helpTagsData))
                object.helpTags = res.data.data.helpTagsData
            } else {
                dispatch(updateInfluencerHelpData(res.data.data))
            }
            return callback(object)
        }).catch(() => {
            const object = {
                currentPage: 1,
                totalPages: 0
            }
            return callback(object)
        })
}

export const addInfluencerHelpData = (data) => {
    return {
        type: ADD_INFLUENCER_HELP_LIST,
        payload: data
    }
}

export const updateInfluencerHelpData = (data) => {
    return {
        type: UPDATE_INFLUENCER_HELP_LIST,
        payload: data
    }
}

export const getInfluencerHelpDetailData = (helpId, callback) => dispatch => {
    axios.get(BASE_URL + `/help/details/${helpId}`)
        .then(res => {
            dispatch(addInfluencerHelpDetailData(res.data.data))
            return callback()
        }).catch(() => {
            return callback()
        })
}

export const addInfluencerHelpDetailData = (data) => {
    return {
        type: ADD_INFLUENCER_HELP_DETAIL,
        payload: data
    }
}

export const influencerHelpResumeAPICall = () => {
    return {
        type: INFLUENCER_HELP_RESUME_API_CALL
    }
}

export const getInfluencerHelpNotificationData = (body, callback) => dispatch => {
    axios.post(BASE_URL + '/help/list/notifications', body)
        .then(res => {
            if (body.pageNum === 1) {
                dispatch(addInfluencerHelpNotificationData(res.data.data))
            } else {
                dispatch(updateInfluencerHelpNotificationData(res.data.data))
            }
            const object = {
                currentPage: res.data.data.currentPage,
                totalPages: res.data.data.totalPages,
                isChangeUnreadToAll: res.data.data.isChangeUnreadToAll
            }
            if (res.data.data.isChangeUnreadToAll === true) {
                dispatch(setNotificationFilter('all'))
            }
            return callback(object)
        }).catch(() => {
            const object = {
                currentPage: 1,
                totalPages: 0
            }
            return callback(object)
        })
}

export const addInfluencerHelpNotificationData = (data) => {
    return {
        type: ADD_INFLUENCER_HELP_LIST_NOTIFICATION,
        payload: data
    }
}

export const updateInfluencerHelpNotificationData = (data) => {
    return {
        type: UPDATE_INFLUENCER_HELP_LIST_NOTIFICATION,
        payload: data
    }
}

export const resetHelpNotification = () => {
    return {
        type: RESET_HELP_NOTIFICATION
    }
}

export const setNotificationFilter = (type) => {
    return {
        type: SET_NOTIFICATION_FILTER,
        payload: type
    }
}

export const setHelpTagFilter = (type) => {
    return {
        type: SET_HELP_TAG_FILTER,
        payload: type
    }
}

export const setHelpTags = (type) => {
    return {
        type: SET_HELP_TAGS,
        payload: type
    }
}

export const getCSVDownload = (callback) => () => {
    axios.get(BASE_URL + '/api/csv-download')
        .then((res) => {
            return callback(res.data.data)
        }).catch(() => {
            return callback()
        })
}

export const saveCSVDownload = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/csv-download', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const getMiscellaneousSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/miscellaneous-settings')
        .then((res) => {
            return callback(res.data.data)
        }).catch(() => {
            return callback()
        })
}

export const saveMiscellaneousSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/miscellaneous-settings', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

/**
 * Get Help Settings
 * @param {*} callback Send the response object
 */
export const getHelpSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/help-settings/get')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
        })
}

/**
 * save Help settings
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const saveHelpSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/help-settings/save', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

export const setIsHelpEnable = (data) => {
    return {
        type: SET_IS_HELP_ENABLE,
        payload: data
    }
}

/**
 * Get Public Post Settings
 * @param {*} callback Send the response object
 */
export const getPublicPostSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/public-post-settings/get')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
        })
}

/**
 * save Public Post settings
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const savePublicPostSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/public-post-settings/save', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            return callback()
        })
}

export const setIsPublicPostEnable = (data) => {
    return {
        type: SET_IS_PUBLIC_POST_ENABLE,
        payload: data
    }
}

/**
 * Get Blog Comments Settings
 */
export const getBlogCommentSettings = () => async () => {
    try {
        const url = `${BASE_URL}/api/blog-comment-setting/get`
        const response = await axios.get(url)
        return response.data.data
    } catch (error) {
        alert(error.message)
    }
}

/**
 * It should be save blog comment setting
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const saveBlogCommentSettings = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/blog-comment-setting/save`
    try {
        const response = await axios.post(url, data)
        const payload = { description: response.data.message }
        dispatch(setSweetAlert(payload))
        return
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
        const payload = { description: errorMessage }
        dispatch(setSweetAlert(payload))
        return
    }
}

export const setIsBlogCommentModeEnable = (data) => {
    return {
        type: SET_IS_BLOG_COMMENTS_ENABLE,
        payload: data
    }
}

export const getShowPaymentIconsSetting = () => async (dispatch) => {
    const url = `${BASE_URL}/api/payment-icons/show-payment-icons`
    try {
        const response = await axios.get(url)
        return response.data.data
    } catch (error) {
        const payload = {
            description: error.message
        }
        dispatch(setSweetAlert(payload))
    }
}

export const saveShowPaymentIconsSetting = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/payment-icons/show-payment-icons`
    try {
        const response = await axios.post(url, data)
        const payload = {
            description: response.data.message
        }
        dispatch(setSweetAlert(payload))
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
    }
}

// Get Model Profile
export const getModelProfile = () => async () => {
    const url = `${BASE_URL}/api/app_settings/get-model-profile`
    try {
        const response = await axios.get(url)
        return response.data.data
    } catch (error) {
        return error.response.data
    }
}

export const getLinkPageData = () => async (dispatch) => {
    const url = `${BASE_URL}/api/app_settings/get_links_page`
    try {
        const response = await axios.get(url)
        dispatch({ type: UPDATE_LINK_PAGE_DATA, payload: response.data.data })
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Something wrong')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
    }
}

/**
 * Get subscription email notification settings
 *
 * @returns {void} return
 */
export const getSubscriptionEmailNotification = () => async (dispatch) => {
    try {
        dispatch(changeSubscriptionEmailNotificationLoading(true))
        const res = await axios.get(BASE_URL + '/api/subscription-email-notification')
        dispatch(changeSubscriptionEmailNotificationValue(res.data.data.send_subscription_email_notifications_to))
        dispatch(changeSubscriptionEmailNotificationLoading(false))
        return
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was a problem fetching subscription email notification settings')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        dispatch(changeSubscriptionEmailNotificationLoading(false))
        return
    }
}

/**
 * Save subscription notification email settings
 *
 * @param {Object} data Request data
 * @returns {void} return
 */
export const saveSubscriptionEmailNotification = (data) => async (dispatch) => {
    try {
        dispatch(changeSubscriptionEmailNotificationButtonLoading(true))
        const res = await axios.post(BASE_URL + '/api/subscription-email-notification', data)
        const payload = {
            description: res.data.message
        }
        dispatch(setSweetAlert(payload))
        dispatch(changeSubscriptionEmailNotificationButtonLoading(false))
        return
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was a problem while updating subscription email notification settings.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        dispatch(changeSubscriptionEmailNotificationButtonLoading(false))
        return
    }
}

/**
 * Change store value of subscription email notification value
 *
 * @param {boolean} payload true | false
 * @returns store action
 */
export const changeSubscriptionEmailNotificationValue = (payload) => {
    return {
        type: SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS,
        payload: payload
    }
}

/**
 * Change store value of loading status
 *
 * @param {boolean} payload true | false
 * @returns executes store action
 */
export const changeSubscriptionEmailNotificationLoading = (payload) => {
    return {
        type: IS_SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS_LOADING,
        payload: payload
    }
}

/**
 * Change store value of button loading status
 *
 * @param {boolean} payload true | false
 * @returns executes store action
 */
export const changeSubscriptionEmailNotificationButtonLoading = (payload) => {
    return {
        type: IS_SUBSCRIPTION_EMAIL_NOTIFICATION_BUTTON_LOADING,
        payload: payload
    }
}

/**
 * Change store value of pagination on exclusive content settings loading status
 *
 * @param {boolean} payload true | false
 * @returns executes store action
 */
export const changePaginationOnExclusiveContentSettingLoading = (payload) => {
    return {
        type: IS_PAGINATION_ON_EXCLUSIVE_CONTENT_SETTING_LOADING,
        payload: payload
    }
}

/**
 * Change store value of pagination on exclusive content settings
 *
 * @param {boolean} payload true | false
 * @returns executes store action
 */
export const setIsPaginationOnExclusiveContentEnabled = (payload) => {
    return {
        type: IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED,
        payload: payload
    }
}

/**
 * Get pagination on exclusive content settings
 *
 * @returns {void} return
 */
export const getPaginationOnExclusiveContentSetting = () => async (dispatch) => {
    try {
        dispatch(changePaginationOnExclusiveContentSettingLoading(true))
        const res = await axios.get(BASE_URL + '/api/pagination-on-exclusive-content')
        dispatch(setIsPaginationOnExclusiveContentEnabled(res.data.data.isPaginationOnExclusiveContentEnabled))
        dispatch(changePaginationOnExclusiveContentSettingLoading(false))
        return
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was a problem while getting pagination on exclusive content settings.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        dispatch(changePaginationOnExclusiveContentSettingLoading(false))
    }
}

/**
 * Change store value of pagination on exclusive content button loading status
 *
 * @param {boolean} payload true | false
 * @returns executes store action
 */
export const changePaginationOnExclusiveContentButtonLoading = (payload) => {
    return {
        type: IS_PAGINATION_ON_EXCLUSIVE_CONTENT_BUTTON_LOADING,
        payload: payload
    }
}

/**
 * Save pagination on exclusive content settings
 *
 * @param {Object} data Request data
 * @returns {void} return
 */
export const savePaginationOnExclusiveContentSetting = (data) => async (dispatch) => {
    try {
        dispatch(changePaginationOnExclusiveContentButtonLoading(true))
        const res = await axios.post(BASE_URL + '/api/pagination-on-exclusive-content', data)
        const payload = {
            description: res.data.message
        }

        const isPaginationOnExclusiveContentEnabled = _.get(res, 'data.data.isPaginationOnExclusiveContentEnabled', false)
        dispatch(setIsExclusiveContentPaginationEnabled(isPaginationOnExclusiveContentEnabled))
        dispatch(restoreFirstPageBlogs())
        dispatch(setSweetAlert(payload))
        dispatch(changePaginationOnExclusiveContentButtonLoading(false))
        return
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'There was a problem while updating pagination on exclusive content settings.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        dispatch(changePaginationOnExclusiveContentSettingLoading(false))
    }
}

export const updateTipsPageData = (payload) => {
    return {
        type: UPDATE_TIPS_PAGE_DATA,
        payload: payload
    }
}

/**
 * Save Theme 2 banner settings
 *
 * @param {Object} data Theme 2 Banner Settings
 * @returns {void}
 */
export const updateTheme2BannerSettings = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_THEME_2_BANNER_SETTINGS,
        payload: data
    })
}

/**
 * Save Theme 2 banner settings
 *
 * @param {Object} data Theme 2 Banner Settings
 * @returns {void}
 */
export const updateTheme2BannerSettingsAfterFileUpload = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_THEME_2_BANNER_SETTINGS_AFTER_FILE_UPLOAD,
        payload: data
    })
}

export const updateAppSettingSingleKey = (key, value) => {
    return {
        type: UPDATE_APP_SETTING_SINGLE_KEY,
        payload: { key, value }
    }
}

/**
 * Get redis cache settings
 *
 * @returns {void} return
 */
export const getRedisCacheSettings = () => async (dispatch) => {
    try {
        dispatch(changeRedisCacheSettingLoading(true))
        const res = await axios.get(BASE_URL + '/api/redis-cache-setting')
        dispatch(setIsRedisCacheEnabled(res.data.data.isRedisCacheEnabled))
        dispatch(changeRedisCacheSettingLoading(false))
        return
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was a problem while getting redis cache settings.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        dispatch(changeRedisCacheSettingLoading(false))
    }
}

/**
 * Change store value of redis cache setting loading status
 *
 * @param {boolean} payload true | false
 * @returns executes store action
 */
export const changeRedisCacheSettingLoading = (payload) => {
    return {
        type: IS_REDIS_CACHE_SETTING_LOADING,
        payload: payload
    }
}

/**
 * Change store value of redis cache settings
 *
 * @param {boolean} payload true | false
 * @returns executes store action
 */
export const setIsRedisCacheEnabled = (payload) => {
    return {
        type: IS_REDIS_CACHE_ENABLED,
        payload: payload
    }
}

/**
 * Save redis cache settings
 *
 * @param {Object} data Request data
 * @returns {void} return
 */
export const saveRedisCacheSetting = (data) => async (dispatch) => {
    try {
        dispatch(changeRedisCacheButtonLoading(true))
        const res = await axios.post(BASE_URL + '/api/redis-cache-setting', data)
        const payload = {
            description: res.data.message
        }

        const isRedisCacheEnabled = _.get(res, 'data.data.isRedisCacheEnabled', false)
        dispatch(setIsRedisCacheEnabled(isRedisCacheEnabled))
        dispatch(setSweetAlert(payload))
        dispatch(changeRedisCacheButtonLoading(false))
        return
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'There was a problem while saving redis cache settings.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        dispatch(changeRedisCacheButtonLoading(false))
    }
}

/**
 * Change store value of redis cache button loading status
 *
 * @param {boolean} payload true | false
 * @returns executes store action
 */
export const changeRedisCacheButtonLoading = (payload) => {
    return {
        type: IS_REDIS_CACHE_BUTTON_LOADING,
        payload: payload
    }
}
